/* @flow */

/* eslint-disable import/no-unassigned-import */
import "core-js/features/object/assign";
import "core-js/features/map";
import "what-input";
/* eslint-enable import/no-unassigned-import */

import { APP_KEY, StoreInfoContext, ClientProvider, createRunQuery } from "entrypoint/shared";
import { registerClient } from "../effects";

import React from "react";
import { hydrate } from "react-dom";
import { Router } from "react-router";
import { AnalyticsProvider } from "context/analytics";
import { createBrowserHistory } from "history";
import fetch from "isomorphic-fetch";
import { HelmetProvider } from "react-helmet-async";
import { watch } from "@crossroads/dev-server";
import { Storage } from "crustate";
import { StorageProvider } from "crustate/react";
import { createClient } from "@awardit/graphql-ast-client";
import { TranslationProvider } from "@awardit/react-use-translate";
import translation from "translation";
import Routes from "components/Routes";
import {
  QuoteData, RouteData, HomeData, OrderData, CheckoutStepData, MessagesData,
  BrandData, SearchData, ViewModeData, PopularData, AllProductsData, CmsData,
} from "../data";
import { googleAnalytics, apiHost } from "../config";
import { markHydrated } from "@crossroads-loyalty-solutions/react-use-browser";

import "theme/_generic/_fonts.scss";
import "theme/_generic/_normalize.scss";
import "theme/_generic/_base.scss";
import "theme/_generic/_typography.scss";

const history = createBrowserHistory({ basename: "/" });

const storage = new Storage();

if (window.snapshot) {
  storage.addModel(QuoteData.model);
  storage.addModel(OrderData.model);
  storage.addModel(RouteData.model);
  storage.addModel(HomeData.model);
  storage.addModel(CheckoutStepData.model);
  storage.addModel(MessagesData.model);
  storage.addModel(BrandData.model);
  storage.addModel(PopularData.model);
  storage.addModel(CmsData.model);
  storage.addModel(SearchData.model);
  storage.addModel(ViewModeData.model);
  storage.addModel(AllProductsData.model);
  storage.restoreSnapshot(window.snapshot);

  delete window.snapshot;
}

const client = createClient({
  runQuery: createRunQuery(fetch, apiHost.client),
  debounce: 5,
});

registerClient(storage, client, history);

window.storage = storage;
window.getState = storage.getSnapshot.bind(storage);

const events = {
  unhandledMessage: "warn",
  // stateCreated: "info",
  // stateRemoved: "info",
  // stateNewData: "info",
  // snapshotRestore: "info",
  // messageQueued: "info",
  // messageMatched: "debug",
  // snapshotRestored: "debug",
};

Object.keys(events).forEach(eventName => {
  const level = events[eventName];
  storage.addListener((eventName: any), (...data) => console[level](eventName, ...data));
});

const root = document.getElementById(APP_KEY);

if (!root) {
  throw new Error("Missing app root");
}

// Boot up watch-process in dev mode to make sure we reload
if (process.env.NODE_ENV !== "production") {
  watch();
}

const currencyCode = window.shop.info.baseCurrencyCode;
const storeCountry = window.shop.info.defaultCountry.code.toLowerCase();

// history.listen((...args) => console.log(JSON.parse(JSON.stringify(args))));

hydrate(
  <StoreInfoContext.Provider value={window.shop}>
    <ClientProvider value={client}>
      <HelmetProvider>
        <TranslationProvider translations={translation || {}}>
          <StorageProvider storage={storage}>
            <MessagesData.Provider>
              <QuoteData.Provider>
                <Router history={history}>
                  <AnalyticsProvider
                    currencyCode={currencyCode}
                    accounts={
                      googleAnalytics
                        .filter(x => x.store === storeCountry)
                        .filter(x => x.currencyCode === currencyCode)
                        .map(x => x.accounts)[0] || []
                    }
                  >
                    <Routes />
                  </AnalyticsProvider>
                </Router>
              </QuoteData.Provider>
            </MessagesData.Provider>
          </StorageProvider>
        </TranslationProvider>
      </HelmetProvider>
    </ClientProvider>
  </StoreInfoContext.Provider>,
  root,
  () => {
    // console.log(storage.getSnapshot());
    markHydrated();
  }
);
