/* @flow */

import React, { useEffect } from "react";
import { SuccessView as SuccessViewExt } from "@crossroads/shop-views";
import { useAnalytics } from "context/analytics";
import { useClient } from "entrypoint/shared";
import { OrderData } from "data";
import { useData } from "crustate/react";
import { load as loadOrder } from "state/order";
import { loadScript } from "helpers/utils";
import useFormat from "helpers/use-format";
import CheckIcon from "icons/checkmark-success.svg";
import ChatIcon from "icons/chat.svg";
import PhoneIcon from "icons/phone.svg";

const googleMapsKey = "AIzaSyABc0nB2QZ9tzKPsC-Bw1POHZj0QMhQ_-Y";

const SuccessView = (): React$Node => {
  const orderData = useData(OrderData);
  const { formatPrice } = useFormat();
  const analytics = useAnalytics();

  useEffect(() =>
    loadScript(
      () => typeof google !== "undefined" && typeof google.maps !== "undefined",
      `https://maps.googleapis.com/maps/api/js?key=${googleMapsKey}&callback=initGoogleMaps`,
      () => {}
    ),
  []);

  return (
    <SuccessViewExt
      orderData={orderData}
      useClient={useClient}
      analytics={analytics}
      formatPrice={formatPrice}
      loadOrder={loadOrder}
      additionalField="CUSTOMER_SERVICE"
      mapComponent={null}
      CheckIcon={<CheckIcon style={{ color: "#fff" }} />}
      ChatIcon={<ChatIcon style={{ height: "24px", width: "24px" }} />}
      PhoneIcon={<PhoneIcon style={{ height: "24px", width: "24px" }} />}
    />
  );
};

export default SuccessView;
