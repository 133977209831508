/* @flow */

import type { Node as ReactNode } from "react";

import React, { useContext, useRef, useEffect, useState } from "react";
import cn from "classnames";
import { useTranslate } from "@awardit/react-use-translate";
import { Dialogue, NotificationBar } from "@crossroads/ui-components";
import { usePreserveScrollPosition } from "react-router-on-navigation";
import Cookies from "js-cookie";
import AppHeader from "components/AppHeader";
import AppFooter from "components/AppFooter";
import { Helmet } from "react-helmet-async";
import { StoreInfoContext } from "entrypoint/shared";
import { ViewModeData, CmsData } from "data";
import { UiProvider } from "helpers/ui";
import { useHistory } from "react-router";
import CookieConsent from "components/CookieConsent";
import useCookie from "helpers/use-cookie";
import CloseIcon from "icons/close-small.svg";

import styles from "./styles.scss";

type Props = {
  children: ReactNode,
};

const App = ({ children }: Props): React$Node => {
  const t = useTranslate();
  const container = useRef(null);
  const history = useHistory();
  const onCheckout = history.location.pathname.includes("checkout") && history.location.pathname !== "/checkout/success";
  const onCountry = history.location.pathname.includes("account/country");
  const { info } = useContext(StoreInfoContext);
  const [couponNotice, setCouponNotice] = useState(false);
  const cookieOpts = { domain: `.${typeof window !== "undefined" ? window.location.host.replace(/:\d+$/, "") : ""}`, expires: 3650 };
  const [consent, setConsent, consentRead] = useCookie("cookie-consent", "initial", cookieOpts);
  const [toggleCookieDialogue, setToggleCookieDialogue] = useState(false);
  const [hideNotificationBar, setHideNotificationBar] = useState(false);

  useEffect(() => {
    if (Cookies.get("coupon") !== undefined && Cookies.get("coupon_message_shown") === "false") {
      setCouponNotice(true);
      Cookies.set("coupon_message_shown", "true");
      history.push("/");
    }
  }, [history]);

  usePreserveScrollPosition(history);

  return (
    <ViewModeData.Provider>
      <CmsData.Provider url="cookie-consent">
        <UiProvider>
          <div
            ref={container} className={cn(
              styles.block,
              { [styles.onCheckout]: onCheckout },
              { [styles.onCountry]: onCountry }
            )}
          >
            <Helmet
              titleTemplate={`${info.titlePrefix || ""} %s ${info.titleSuffix || ""}`}
              link={[
                { rel: "apple-touch-icon", sizes: "57x57", href: "/assets/browser/apple-touch-icon-57x57.png" },
                { rel: "apple-touch-icon", sizes: "60x60", href: "/assets/browser/apple-touch-icon-60x60.png" },
                { rel: "apple-touch-icon", sizes: "72x72", href: "/assets/browser/apple-touch-icon-72x72.png" },
                { rel: "apple-touch-icon", sizes: "76x76", href: "/assets/browser/apple-touch-icon-76x76.png" },
                { rel: "apple-touch-icon", sizes: "114x114", href: "/assets/browser/apple-touch-icon-114x114.png" },
                { rel: "apple-touch-icon", sizes: "120x120", href: "/assets/browser/apple-touch-icon-120x120.png" },
                { rel: "apple-touch-icon", sizes: "144x144", href: "/assets/browser/apple-touch-icon-144x144.png" },
                { rel: "apple-touch-icon", sizes: "152x152", href: "/assets/browser/apple-touch-icon-152x152.png" },
                { rel: "apple-touch-icon", sizes: "180x180", href: "/assets/browser/apple-touch-icon-180x180.png" },
                { rel: "apple-touch-icon", href: "/assets/browser/apple-touch-icon-120x120-precomposed.png" },
                { rel: "icon", type: "image/png", size: "16x16", href: "/assets/browser/favicon-16x16.png" },
                { rel: "icon", type: "image/png", size: "32x32", href: "/assets/browser/favicon-32x32.png" },
                { rel: "icon", type: "image/png", size: "96x96", href: "/assets/browser/favicon-96x96.png" },
                { rel: "manifest", href: "/manifest.json" },
                { rel: "mask-icon", href: "/assets/browser/safari-pinned-tab.svg", color: "#da2911" },
              ]}
              meta={[
                { name: "msapplication-TileColor", content: "#ffffff" },
                { name: "theme-color", content: "#ffffff" },
              ]}
              htmlAttributes={{ lang: info.locale.slice(0, 2) }}
            />
            <div className={styles.top}>
              {(!onCountry && !onCheckout) &&
                <AppHeader />
              }
              {(!onCheckout) &&
                <div className={cn(styles.height, { [styles.onCheckout]: onCheckout })} />
              }
              <div className={cn(styles.wrapper, { [styles.onCheckout]: onCheckout })}>
                {children}
              </div>
            </div>

            {consentRead &&
            <div className={styles.notices}>
              <CookieConsent
                isVisible={consentRead && (consent === "initial" || toggleCookieDialogue)}
                consent={consent}
                setConsent={setConsent}
                consentRead={consentRead}
                setToggleCookieDialogue={setToggleCookieDialogue}
              />
            </div>
            }

            {(!onCheckout) && (
              <AppFooter
                className={styles.footer}
                setToggleCookieDialogue={setToggleCookieDialogue}
              />
            )}

            {couponNotice && (
              <Dialogue
                open
                className={styles.confirmDialogue}
                setOpen={() => setCouponNotice(false)}
                title={t("COUPON_LIGHTBOX.TITLE")}
                closeIcon={<div className={styles.closeIconWrapper}><CloseIcon /></div>}
                primaryAction={t("COUPON_LIGHTBOX.CTA")}
                secondaryAction={t("CONFIRM_DIALOG.CANCEL")}
                onPrimaryAction={() => setCouponNotice(false)}
                onSecondaryAction={() => setCouponNotice(false)}
              >
                {t("COUPON_LIGHTBOX.INFO")}
              </Dialogue>
            )}
          </div>
          {info.popUp && info.popUp.body !== "" && !hideNotificationBar &&
            <NotificationBar
              className={styles.notificationBar}
              text={info.popUp.body}
              onClose={() => setHideNotificationBar(true)}
            />
          }
        </UiProvider>
      </CmsData.Provider>
    </ViewModeData.Provider>
  );
};

export default App;
