/* @flow */

import React, { useState } from "react";
import cn from "classnames";
import { useData } from "crustate/react";
import { useTranslate } from "@awardit/react-use-translate";
import { Button, Dialogue } from "@crossroads/ui-components";
import { CmsData } from "data";
import { CheckboxField } from "components/Field";
import CmsPageView from "components/CmsPageView";
import CloseIcon from "icons/cross.svg";

import styles from "./terms.scss";

const Terms = (): React$Node => {
  const t = useTranslate();
  const termsData = useData(CmsData);
  const [showTerms, setShowTerms] = useState(false);

  return (
    <>
      {termsData.state === "LOADED" &&
        <Dialogue
          className={styles.modal}
          open={showTerms}
          setOpen={() => setShowTerms(false)}
        >
          <Button
            className={styles.close_button}
            onClick={() => setShowTerms(false)}
          >
            <CloseIcon />
          </Button>
          <div className={styles.container}>
            <CmsPageView showBreadcrumbs={false} cmsPage={termsData.data} />
          </div>
        </Dialogue>
      }

      <div className={styles.terms}>
        <CheckboxField
          id="terms"
          name="terms"
        >
          <span>{t("CHECKOUT.I_ACCEPT_THE")}{" "}</span>
          <span
            className={cn({ [styles.link]: termsData.state === "LOADED" })}
            onClick={e => {
              e.preventDefault();
              setShowTerms(true);
            }}
          >
            {t("CHECKOUT.TERMS")}
          </span>
        </CheckboxField>
      </div>
    </>
  );
};

export default Terms;
