module.exports = {
  host: "localhost",
  port: 9148,
  apiHost: {
    server: "http://127.0.0.1",
    client: "",
    headers: ["SSL", "Ssl-Offload", "X-Forward-Proto", "Host", "X-Real-Ip", "X-Forwarded-For", "Cookie"],
  },
  googleAnalytics: [
    { store: "se", currencyCode: "SEK", accounts: ["UA-5572085-45", "G-FYRZEWZGR1"] },
    { store: "no", currencyCode: "NOK", accounts: ["UA-5572085-97", "G-EN8E73QE7Z"] },
    { store: "fi", currencyCode: "EUR", accounts: ["UA-5572085-106", "G-CRL3NG9P9S"] },
  ],
  isProduction: true,
  proxyHost: null,
  collector: {
    src: "https://checkout.collector.se/collector-checkout-loader.js",
  },
  cspUri: "https://csp-report.browser-intake-datadoghq.eu/api/v2/logs?dd-api-key=pub552607268219ad5b8d2647030dd2f46f&dd-evp-origin=content-security-policy&ddsource=csp-report&ddtags=service%3Ashop.sectoralarm.se%2Cenv%3Aproduction",
};
