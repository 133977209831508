/* @flow */

import React, { useEffect } from "react";
import cn from "classnames";
import { Helmet } from "react-helmet-async";
import { Route, Switch, useHistory } from "react-router";
import { useAnalytics } from "context/analytics";
import { useTranslate } from "@awardit/react-use-translate";
import Redirect from "@crossroads/react-router-redirect";
import { getQuoteData } from "state/quote";
import { useData } from "crustate/react";
import { QuoteData } from "data";
import Wrapper from "components/Wrapper";
import Header from "components/AppHeader/StepHeader/checkout-header";
import Button from "components/Button";
import useCheckoutSteps from "helpers/use-checkout-steps";
import ArrowIcon from "icons/arrow.svg";

import Cart from "components/CheckoutView/Cart";
import Overview from "components/CheckoutView/Overview";

import styles from "./styles.scss";

const CheckoutView = (): React$Node => {
  const t = useTranslate();
  const history = useHistory();
  const quoteData = useData(QuoteData);
  const quoteState = useData(QuoteData);
  const quote = getQuoteData(quoteState);
  const { currentStep } = useCheckoutSteps();
  const analytics = useAnalytics();

  useEffect(() => {
    if (!currentStep) {
      return;
    }

    if (currentStep.key === 2) {
      if (quote) {
        analytics.registerBeginCheckoutProcess(
          quote,
          quote.grandTotal.incVat
        );
      }
    }
    else {
      analytics.registerCheckoutStep(
        currentStep.key,
        currentStep.id
      );
    }
  }, [currentStep]);

  if (quoteData.state === "LOADING" || !quote) {
    return null;
  }

  if (quoteData.state === "LOADED" && quoteData.data.items.length === 0) {
    return (
      <div className={styles.block}>
        <Header />

        <Wrapper>
          <header className={styles.header}>
            <h1 className={styles.heading}>{t("CART.EMPTY")}</h1>
            <Button
              className={cn(styles.back, "link")}
              slotLeft={<ArrowIcon style={{ transform: "rotate(180deg)" }} />}
              onClick={() => history.push("/")}
            >
              {t("CHECKOUT.CONTINUE_SHOPPING")}
            </Button>
          </header>
        </Wrapper>
      </div>
    );
  }

  const totalItems = quote.items.reduce((total, item) => total + item.qty, 0);

  return (
    <div className={styles.block}>
      <Helmet
        title={currentStep ? currentStep.title : t("CHECKOUT.STEPS.CART.SHORT")}
      />

      <Header />

      <Wrapper>
        <header className={styles.header}>
          <h1 className={styles.heading}>
            {currentStep ? currentStep.titleLong : t("CHECKOUT.STEPS.CART.LONG")}{currentStep.id === "cart" ? ` (${totalItems})` : ""}
          </h1>
          <Button
            className={cn("link", styles.back)}
            slotLeft={<ArrowIcon style={{ transform: "rotate(180deg)" }} />}
            onClick={() => history.push("/")}
          >
            {t("CHECKOUT.CONTINUE_SHOPPING")}
          </Button>
        </header>

        <Switch>
          <Route exact path="/checkout/payment" render={() => <Overview quote={quoteData.data} />} />
          <Route exact path="/checkout" render={() => <Cart />} />
          <Route path="/" render={() => <Redirect to="/checkout" />} />
        </Switch>
      </Wrapper>
    </div>
  );
};

export default CheckoutView;
