/* @flow */

import type { ShippingAddressInput, BillingAddressInput, Quote } from "shop-state/types";

import React, { useState, useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useSendMessage, useData } from "crustate/react";
import { setAddresses } from "@crossroads/shop-state/quote";
import {
  Form,
  rules,
  nestedRule,
  conditional,
  isEmail,
  isPhone,
  isRequired,
} from "@awardit/formaggio";
import { useTranslate } from "@awardit/react-use-translate";
import { addMessage, addMessageTranslated } from "@crossroads/shop-state/messages";

import { QuoteData } from "data";
import usePrevious from "helpers/use-previous";
import { getQuoteData } from "state/quote";
import { focusInvalidField } from "helpers/utils";
import { StoreInfoContext, useClient } from "entrypoint/shared";
import AddressComponent from "components/Address";
import Container from "components/CheckoutView/Container";
import Button from "components/Button";
import { CheckboxField } from "components/Field";
import ContactInfoComponent from "components/ContactInfo";
import CustomerServiceLink from "components/CheckoutView/CustomerServiceLink";
import CartSummary from "components/CartSummary";
import Terms from "./terms";
import { placeOrder as placeOrderQuery } from "queries";

import styles from "./styles.scss";

export type FormState = {
  billing: BillingAddressInput,
  shipping: ShippingAddressInput,
  email: string,
  shipToSameAddress: boolean,
  checkDiscountCode: string,
  terms: boolean,
};

const validationAddress = () => rules([
  isRequired("firstname"),
  isRequired("lastname"),
  isRequired("postcode"),
  isPhone("telephone"),
  isRequired("city"),
  nestedRule("street", rules([
    isRequired("0"),
  ])),
  isRequired("countryCode"),
]);

const validation = rules([
  isRequired("terms"),
  isEmail("email"),
  isRequired("billing"),
  nestedRule("billing", validationAddress()),

  conditional(s => s.shipToSameAddress === false, rules([
    isRequired("shipping"),
    nestedRule("shipping", validationAddress()),
  ])),
]);

const initializeFormState = (quote: ?Quote, countries) => () => {
  const addresses = quote?.addresses || [];
  const quoteBilling = addresses.find(x => x.type === "billing");
  const quoteShipping = addresses.find(x => x.type === "shipping");
  const validQuoteBillingCountry = countries.find(c => c.code === quoteBilling?.country.code);
  const validQuoteShippingCountry = countries.find(c => c.code === quoteShipping?.country.code);

  if (!validQuoteBillingCountry) {
    return {
      email: "",
      telephone: "",
      billing: {
        firstname: "",
        lastname: "",
        city: "",
        countryCode: "",
        postcode: "",
        street: [""],
        telephone: "",
      },
      shipping: {
        firstname: "",
        lastname: "",
        city: "",
        countryCode: "",
        postcode: "",
        street: [""],
        telephone: "",
      },
      shipToSameAddress: true,
      checkDiscountCode: "",
      terms: false,
    };
  }

  return {
    email: quote?.email || "",
    telephone: "",
    billing: {
      ...(quoteBilling: any),
      countryCode: validQuoteBillingCountry.code,
    },
    shipping: {
      ...(quoteShipping: any),
      countryCode: validQuoteShippingCountry?.code ||
        validQuoteBillingCountry.code,
    },
    shipToSameAddress: quoteBilling?.type === "billing" ? quoteBilling.isUsedAsShipping : false,
    checkDiscountCode: "",
    terms: false,
  };
};

const useFreeCheckout = () => {
  const client = useClient();
  const history = useHistory();
  const sendMessage = useSendMessage();
  const { info: { countries } } = useContext(StoreInfoContext);
  const quoteData = useData(QuoteData);
  const previosQuoteState = usePrevious(quoteData.state);
  const quote = getQuoteData(quoteData);
  const [formState, setFormState] = useState<FormState>(initializeFormState(quote, countries));
  const [isSubmittingOrder, setIsSubmittingOrder] = useState<boolean>(false);

  const placeOrder = async () => {
    try {
      const placeOrderData = await client(placeOrderQuery);

      if (placeOrderData.placeOrder.result !== "success") {
        sendMessage(addMessage(placeOrderData.placeOrder.result, "error"));
        setIsSubmittingOrder(false);
        return;
      }

      history.push("/checkout/success");
    }
    catch (e_) {
      sendMessage(addMessageTranslated(e_.message, "error"));
      setIsSubmittingOrder(false);
    }
  };

  useEffect(() => {
    if (previosQuoteState === "SETTING_ADDRESS" && quoteData.state === "LOADED") {
      if (quote && quote.validationErrors.length === 0) {
        placeOrder();
      }
      else {
        setIsSubmittingOrder(false);
      }
    }
  }, [quoteData.state]);

  const submitOrder = (e: SyntheticEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!formState.terms) {
      return;
    }

    setIsSubmittingOrder(true);

    const billing = {
      firstname: formState.billing.firstname,
      lastname: formState.billing.lastname,
      street: formState.billing.street,
      postcode: formState.billing.postcode,
      city: formState.billing.city,
      countryCode: formState.billing.countryCode,
      telephone: formState.billing.telephone,
    };

    const shipping = {
      firstname: formState.shipping.firstname,
      lastname: formState.shipping.lastname,
      street: formState.shipping.street,
      postcode: formState.shipping.postcode,
      city: formState.shipping.city,
      countryCode: formState.shipping.countryCode,
      telephone: formState.shipping.telephone,
    };

    sendMessage(setAddresses(formState.email, billing, shipping, formState.shipToSameAddress));
  };

  return {
    submitOrder,
    formState,
    setFormState,
    isSubmittingOrder,
  };
};

const Free = (): React$Node => {
  const t = useTranslate();
  const {
    formState,
    isSubmittingOrder,
    setFormState,
    submitOrder } = useFreeCheckout();

  const errors = validation((formState: any));
  const [open, setOpen] = useState(true);

  return (
    <Form
      value={(formState: any)}
      errors={errors}
      onError={focusInvalidField}
      onChange={(x: any) => {
        setFormState({ ...formState, ...x });
      }}
      onSubmit={submitOrder}
    >
      <Container
        right={
          <div>
            <CartSummary setOpen={setOpen} open={open}>
              <div className={styles.submitButtonContainer}>
                <Terms />
                <Button
                  className={styles.submitButton}
                  type="submit"
                  variant="primary"
                  loading={isSubmittingOrder}
                  disabled={!formState.terms}
                >
                  {t("CHECKOUT.CONFIRM_ORDER")}
                </Button>
              </div>
            </CartSummary>

            <CustomerServiceLink />
          </div>
        }
      >

        <div className={styles.left}>
          <div className={styles.row}>
            <ContactInfoComponent type="billing" />
            <AddressComponent type="billing" />
          </div>

          <div className={styles.checkbox}>
            <CheckboxField
              className={styles.checkbox}
              name="shipToSameAddress"
              checked={formState.shipToSameAddress}
            >
              <span>
                {t("CHECKOUT.ADDRESS.SHIP_TO_SAME_ADDRESS")}
              </span>
            </CheckboxField>
          </div>

          {!formState.shipToSameAddress &&
            <>
              <h2 className={styles.heading}>{t("CHECKOUT.ADDRESS.SHIPPING")}</h2>
              <div className={styles.row}>
                <AddressComponent type="shipping" />
              </div>
            </>
          }
        </div>
      </Container>
    </Form>
  );
};

export default Free;
