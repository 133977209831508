/* @flow */

import type {
  AnalyticsProviderProps,
  AnalyticsContextType as GoogleAnalyticsContextType,
  ProductSubset,
  Order,
  Quote } from "shop-state/types";

import React, { createContext, useContext, useMemo } from "react";
import { WithAnalytics, AnalyticsContext as GoogleAnalyticsContext } from "@crossroads/analytics";

  type Props = AnalyticsProviderProps & {
    children: React$Node,
  };

  type PropsInner = {
    children: React$Node,
  };

  type AnalyticsContextType = {
    ...GoogleAnalyticsContextType,
    registerModifyCart: (product: ProductSubset, diff: number, value: number) => void,
    registerCheckoutSuccess: (order: Order, coupon?: string) => void,
    registerBeginCheckoutProcess: (quote: Quote, value: number) => void,
    grantConsentNecessary: () => void,
    grantConsentStatistical: () => void,
    denyConsentStatistical: () => void,
  };

/**
   * No operation function
   */
export const noop = (): typeof undefined => undefined;

const AnalyticsContext = createContext<AnalyticsContextType>({
  g: noop,
  register: (): number => 0,
  unregister: noop,
  registerProductClick: noop,
  registerProductDetailsView: noop,
  registerModifyCart: noop,
  registerBeginCheckoutProcess: noop,
  registerCheckoutProgress: noop,
  registerCheckoutStep: noop,
  registerCheckoutSuccess: noop,
  grantConsentNecessary: noop,
  grantConsentStatistical: noop,
  denyConsentStatistical: noop,
  searchTerm: noop,
  viewedCart: noop,
});

const AnalyticsProvider = ({ children, ...rest }: Props): React$Node => {
  return (
    <WithAnalytics {...rest}>
      <AnalyticsProviderInner>
        {children}
      </AnalyticsProviderInner>
    </WithAnalytics>
  );
};

const AnalyticsProviderInner = ({ children }: PropsInner): React$Node => {
  const gaContext = useContext(GoogleAnalyticsContext);

  const registerModifyCart = (product: ProductSubset, diff: number, value: number) => {
    if (diff === 0) {
      return;
    }

    gaContext.registerModifyCart(product, diff > 0 ? "add_to_cart" : "remove_from_cart", value);
  };

  const registerBeginCheckoutProcess = (quote: Quote, value: number) => {
    gaContext.registerBeginCheckoutProcess(quote.items, value);
  };

  const registerCheckoutSuccess = (order: Order, coupon?: string) => {
    gaContext.registerCheckoutSuccess(order, order.grandTotal?.incVat || 0, coupon);
  };

  const grantConsentStatistical = () => {
    gaContext.grantConsentStatistical();
  };

  const denyConsentStatistical = () => {
    gaContext.denyConsentStatistical();
  };

  const value = useMemo(() => ({
    g: gaContext.g,
    register: gaContext.register,
    unregister: gaContext.unregister,
    registerProductClick: gaContext.registerProductClick,
    registerProductDetailsView: gaContext.registerProductDetailsView,
    registerModifyCart,
    registerBeginCheckoutProcess,
    registerCheckoutProgress: gaContext.registerCheckoutProgress,
    registerCheckoutStep: gaContext.registerCheckoutStep,
    registerCheckoutSuccess,
    grantConsentNecessary: gaContext.grantConsentNecessary,
    grantConsentStatistical,
    denyConsentStatistical,
    searchTerm: gaContext.searchTerm,
    viewedCart: gaContext.viewedCart,
  }), []);

  return (
    <AnalyticsContext.Provider value={value}>
      {children}
    </AnalyticsContext.Provider>
  );
};

const useAnalytics = (): AnalyticsContextType => {
  const context = useContext(AnalyticsContext);

  return context;
};

export {
  AnalyticsProvider,
  useAnalytics,
};

