/* @flow */

import type { CmsPage } from "shop-state/types";

import React, { useRef, useEffect } from "react";
import { Helmet } from "react-helmet-async";
import Wrapper from "components/Wrapper";
import Breadcrumbs from "components/Breadcrumbs";
import { useGetCmsMeta } from "helpers/get-meta";

import styles from "./styles.scss";

type Props = {
  cmsPage: CmsPage,
};

const toggleAccordionTitle = (e: MouseEvent) => {
  if (e.target instanceof HTMLElement) {
    const element = e.target.parentElement;

    if (element) {
      const isOpen = element.classList.contains("open");

      if (!isOpen) {
        element.classList.add("open");
      }
      else {
        element.classList.remove("open");
      }
    }
  }
};

const CmsPageView = ({ cmsPage }: Props): React$Node => {
  const meta = useGetCmsMeta(cmsPage);
  const root = useRef<?HTMLDivElement>();

  useEffect(() => {
    const { current } = root;

    if (current) {
      // Bind accordions here
      const accordions = current.querySelectorAll(".cms__accordion");

      for (const e of accordions) {
        if (e) {
          const title = e.querySelector(".cms__accordionTitle");
          if (title) {
            title.addEventListener("click", toggleAccordionTitle);
          }
        }
      }
    }

    // Un-bind accordions
    return () => {
      if (current) {
        const accordions = current.querySelectorAll(".cms__accordion");
        for (const e of accordions) {
          e.removeEventListener("click", toggleAccordionTitle);
        }
      }
    };
  }, [root]);

  return (
    <Wrapper className={styles.wrapper}>
      <Helmet
        title={meta.title}
        meta={meta.data}
        link={meta.link}
      />

      <Breadcrumbs current={cmsPage.contentHeading} />

      {cmsPage.contentHeading ?
        <h1 className="h2">{cmsPage.contentHeading}</h1> :
        null
      }

      <div key={cmsPage.url} dangerouslySetInnerHTML={{ __html: cmsPage.content }} ref={root} className="typography" />
    </Wrapper>
  );
};

export default CmsPageView;
